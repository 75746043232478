import {inject, Injectable} from "@angular/core";
import { Observable } from "rxjs";
import {StoreEntity} from "../../shared/components/table/store/entity.store";

import {DesignsService} from "../services/designs.service";
import {IDesign} from "../models/design-response.model";

@Injectable({
  providedIn: 'root',
})
export class DesignsStore extends StoreEntity<IDesign[]> {

  designsService = inject(DesignsService)

  getEntity(id: string): Observable<IDesign[]> {
    return this.designsService.getDesigns(id);
  }

}
