import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {authGuardFn} from "@auth0/auth0-angular";


const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./core/core.module').then((m) => m.CoreModule),
    canActivate: [authGuardFn],
  },
  {path: '**', redirectTo: ''}, // mock - 404 goes here
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    bindToComponentInputs: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
