import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AngularSvgIconModule} from "angular-svg-icon";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule,} from "@angular/common/http";
import {AngularSvgIconPreloaderModule} from "angular-svg-icon-preloader";
import {AuthHttpInterceptor, provideAuth0} from "@auth0/auth0-angular";
import {environment} from "../environments/environment";
import {NgxWebstorageModule} from "ngx-webstorage";
import {BrowserAnimationsModule, provideAnimations} from "@angular/platform-browser/animations";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {ClientsStoreTable} from "./clients/store/clients.store";
import {GlobalVariablesService} from "./shared/services/global-variables.service";
import {CompanyStore} from "./company/store/company.store";
import * as Sentry from "@sentry/angular-ivy";
import {EmployeesStore} from "./employees/store/employees.store";
import {EmployeeStore} from "./employees/store/employee.store";
import {DesignsStore} from "./designs/store/designs.store";
import {ProjectsStore} from "./projects/store/projects.store";
import {ProjectStore} from "./projects/store/project.store";
import {StripeSessionStore} from "./setup/store/stripe-session.store";
import {LoadingPageInterceptor} from "./core/interceptors/loading-page.interceptor";


export class CustomLoader extends TranslateHttpLoader {
  constructor(http: HttpClient) {
    super(http, '/assets/i18n/', '.json');
  }
}

const auth0Providers = [
  provideAuth0({
    domain: environment.AUTH0_DOMAIN,
    clientId: environment.AUTH0_CLIENT_ID,
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: environment.AUTH0_AUDIENCE,
    },
    cacheLocation: "localstorage",
    httpInterceptor: {
      allowedList: [
        environment.URL,
        environment.URL + '*'
      ]
    }
  }),
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthHttpInterceptor,
    multi: true
  },
];

const productionProviders = [
  {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: true,
    }),
  },
];


const environmentProviders = environment.PRODUCTION
  ? productionProviders
  : [];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    AngularSvgIconPreloaderModule.forRoot({
      configUrl: './assets/json/icons.json',
    }),
    NgxWebstorageModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'en-us',
      loader: {
        provide: TranslateLoader,
        useClass: CustomLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    provideAnimations(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingPageInterceptor,
      multi: true
    },
    ClientsStoreTable,
    GlobalVariablesService,
    CompanyStore,
    EmployeesStore,
    EmployeeStore,
    DesignsStore,
    ProjectsStore,
    ProjectStore,
    StripeSessionStore,
    ...auth0Providers,
    ...environmentProviders,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


