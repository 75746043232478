import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {languages} from "./languages.list";
import {EmployeeService} from "./employees/services/employee.service";
import {IEmployee} from "./employees/models/employee.model";
import {catchError, filter, firstValueFrom, from, tap} from "rxjs";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private readonly languages: string[] = languages;

  constructor(
    private readonly translateService: TranslateService,
    private readonly employeeService: EmployeeService
  ) {}

  async ngOnInit() {
    this.translateService.setDefaultLang('en-us');
    this.translateService.addLangs(this.languages);

    const browserLanguages$ = from(navigator.languages)
      .pipe(map(c => c.toLowerCase()));

    await firstValueFrom(
      this.employeeService.getLoggedEmployee().pipe(
        map((employee: IEmployee) => employee.language),
        catchError(() => browserLanguages$),
        filter(code => this.languages.includes(code)),
        tap(foundCode => this.translateService.use(foundCode))
      )
    );
  }
}
