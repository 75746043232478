import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DesignsService {

  DESIGNS_PATH = environment.URL + 'v0.1/designs'

  http = inject(HttpClient)

  public getDesigns(projectId: string): Observable<any> {
    return this.http
      .get<any[]>(`${this.DESIGNS_PATH}?project=${projectId}`)
  }

}

